<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'Whatsapp'" :subtitle="$tc('app.report', 2)" />
    <div class="page-content container-fluid">
      <div class="card card-tabs">
        <div class="card-header">
          <ul class="nav nav-tabs primary-tabs">
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link active"
                v-tab="'history'"
                data-toggle="tab"
                aria-expanded="true">
                <span class="material-symbols-outlined"> history </span>
                {{$t('app.history')}}
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <router-link
                :to="{ name: 'WhatsAppNewCampaign' }"
                class="nav-link"
                replace
                ><span class="material-symbols-outlined"> campaign </span
                >{{ $tc('app.campaign', 2) }}</router-link
              >
            </li>
          </ul>
          <div class="container-fluid">
            <div class="form-row">
              <div class="col-md-2 mb-6">
                <div class="form-group">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control search-form"
                      :placeholder="$t('sms.history.lbl-search-id')"
                      v-model="form.id"
                      aria-label
                      aria-describedby="basic-addon2"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn icon_btn m-0 btn-search-id"
                        type="button"
                        @click="fetch(form.page)"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!$store.state.account.whitelabel_id"
                class="form-group col-lg-2 col-md-2 col-sm-12"
              >
                <select-input
                  label="name"
                  v-model="form.customer_ids"
                  :reduce="(field) => field.id"
                  :options="customers"
                  :clearable="true"
                  multiple
                />
              </div>
              <div class="col-lg-2 col-md-2">
                <template>
                  <div>
                    <date-range-picker
                      class="form-control"
                      value
                      :options="options"
                      @change="changeDate"
                    />
                  </div>
                </template>
              </div>
              <div class="col-lg-2 col-md-2">
                <input
                  class="form-control"
                  :placeholder="$t('generic-str.lbl-sender')"
                  v-model="form.sender"
                />
              </div>
              <div class="col-lg-2 col-md-2">
                <input
                  class="form-control"
                  :placeholder="$t('generic-str.lbl-recipient')"
                  v-model="form.recipient"
                />
              </div>
              <div class="col-lg-2 col-md-2 d-flex">
                <select class="form-control col mr-2" v-model="form.status">
                  <option selected value>Status</option>
                  <!--
                  QUEUED
                  SENT
                  DELIVERED
                  READ
                  FAILED
                  CANCELED
                  PAUSED
                  DENYLIST
                  -->
                  <option value="QUEUED">{{$tc('generic-str.status.lbl-pending', 1)}}</option>
                  <!-- <option value="sending">{{$t('generic-str.status.lbl-sending')}}</option> -->
                  <option value="SENT">{{$t('generic-str.status.lbl-sent')}}</option>
                  <option value="FAILED">{{$t('generic-str.status.lbl-failure')}}</option>
                  <option value="DELIVERED">{{$t('generic-str.status.lbl-delivered')}}</option>
                  <option value="READ">{{$t('generic-str.status.lbl-readed')}}</option>
                  <option value="DENYLIST">Vetado</option>
                  <!-- <option value="received">{{$tc('generic-str.status.lbl-response', 2)}}</option> -->
                </select>
                <button class="btn btn-primary" @click="fetch(1)">
                  {{$t('generic-str.filter')}}
                </button>
              </div>
              <!-- <div class="col-lg-2 col-md-2">
                <div class="form-group">
                  <div class="input-group">
                    <div class>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="form-row">
            </div>
          </div>
        </div>
        <div class="card-body block-el p-0">
          <div class="tab-content">
            <div id="history" class="tab-pane active">
              <div class="responsive-table">
                <table v-if="fetched" class="table table-borderless">
                  <thead class="">
                    <tr>
                      <th scope="col">{{$t('generic-str.date')}}</th>
                      <th scope="col">{{$t('generic-str.type')}}</th>
                      <th scope="col">{{$t('generic-str.lbl-sender')}}</th>
                      <th scope="col">{{$t('generic-str.lbl-recipient')}}</th>
                      <th scope="col">{{$tc('generic-str.message', 1)}}</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="message in messages"
                      :key="message.id"
                      :to="`history/${message.id}`">
                      <td data-label="Data">
                        <div>
                          <pre class="sr-only">{{message}}</pre>
                          <router-link :to="`history/${message.id}`">
                            {{ message.date | formatDate24}}
                          </router-link>
                        </div>
                      </td>
                      <td data-label="Tipo">
                        {{message.type}}
                        <!-- <template v-if="message.type == 'Envio'">
                          {{$t('generic-str.send-2')}}
                        </template>
                        <template v-else-if="message.direction == 'Resposta'">
                          {{$tc('generic-str.status.lbl-response', 1)}}
                        </template> -->
                      </td>
                      <td data-label="Remetente">{{ message.sender }}</td>
                      <td data-label="Destinatário">{{ message.recipient }}</td>
                      <td
                        data-label="Mensagem"
                        class="max-200"
                        v-tooltip.top="`${message.text}`"
                      >
                        {{ message.text }}
                      </td>
                      <td data-label="Status">
                        <span
                          v-if="message.status == 'READ'"
                          class="badge badge-readed">
                          {{$t('generic-str.status.lbl-readed')}}</span>
                        <span
                          v-if="message.status == 'ACCEPTED'"
                          class="badge badge-pending">
                          {{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span
                          v-if="message.status == 'SCHEDULED'"
                          class="badge badge-scheduled"
                          >Agendado</span>
                        <span
                          v-else-if="message.status == 'QUEUED'"
                          class="badge badge-secondary">
                          {{$t('generic-str.status.lbl-queue')}}</span>
                        <span
                          v-else-if="message.status == 'SENDING'"
                          class="badge badge-accent">
                          {{$t('generic-str.status.lbl-sending')}}</span>
                        <span
                          v-else-if="message.status == 'SENT'"
                          class="badge badge-sent">
                          {{$t('generic-str.status.lbl-sent')}}</span>
                        <span
                          v-else-if="message.status == 'FAILED'"
                          class="badge badge-danger"
                          v-tooltip.top="
                            `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                          ">
                          {{$t('generic-str.status.lbl-failure')}}</span>
                        <span
                          v-else-if="message.status == 'DELIVERED'"
                          class="badge badge-delivered">
                          {{$t('generic-str.status.lbl-delivered')}}</span>
                        <span
                          v-else-if="message.status == 'UNDELIVERED'"
                          class="badge badge-rejected"
                          v-tooltip.top="
                            `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                          ">
                          {{$t('generic-str.status.lbl-unavailable')}}</span>
                        <span
                          v-else-if="message.status == 'RECEIVED'"
                          class="badge badge-delivered">
                          {{$tc('generic-str.status.lbl-response', 1)}}</span>
                        <span
                          v-else-if="message.status == 'DENYLIST'"
                          class="badge badge-danger">
                          Vetado</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="static qt-block-ui" style="padding: 120px" />
                <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
              <div v-if="!messages.length && fetched" class="text-center table-placeholder p-3 pb-4">
                <!-- <i class="fas fa-comment font-size-80"></i> -->
                <LottieAnimNoData />
                <h5 class="card-title m-t-20">{{$t('wpp-component.none')}}</h5>
                <p>{{$t('wpp-component.first-msg')}}</p>
                <router-link to="/whatsapp/send" type="button" class="btn btn-primary">
                  {{$t('wpp-component.new-msg')}}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card-footer">
          <button
            @click="exportMessages"
            class="btn btn-success"
            :class="{ 'qt-loader qt-loader-mini qt-loader-left': exporting }"
          >
            {{$t('generic-str.export')}} <! -- Exportar -- >
          </button>
          <router-link to="/whatsapp/exports" class="btn btn-info btn-export"
            >{{$t('sms.history.lbl-reports')}}</router-link> <! -- Relatórios Exportados -- >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import moment from 'moment';
import WhatsService from '@/services/whatsapp.service';
import CustomerService from '@/services/customer.service';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Pagination from '@/components/rebranding/Pagination.vue';
import SelectInput from '@/components/SelectInput.vue';
// import moment from 'moment';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
    SelectInput,
    LottieAnimNoData,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/jv/reports/history',
        2: '/whatsapp/jv/reports/history',
      },
      exporting: false,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      customers: [],
      messages: [],
      beginDate: '',
      endDate: '',
      form: {
        status: null,
        sender: null,
        recipient: null,
        date_begin: null,
        date_end: null,
      },
      pages: 0,
    };
  },
  created() {
    if (!this.$store.state.account.whitelabel_id) {
      this.fetchCustomers();
    }
  },
  mounted() {
    this.changeDate();
  },
  methods: {
    exportMessages() {
      this.exporting = true;
      WhatsService.exportMessages({
        channel: 'whatsapp',
        name: 'whats-report',
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/whatsapp/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
    fetch(page) {
      console.log('FETCH WHATSAPP');

      this.form.page = page - 1;
      this.fetched = false;

      /*
      whatsapp_business.account_id;eq;''
      status;eq;'READ'
      message_origin;eq;'CLIENT'
      client_number;eq;'5511999892607'
      timestamp;gte;'2022-11-01'
      timestamp;lte;'2022-11-30'
      orderBy
      timestamp;desc
      */

      // query=x&query=y&page=1

      const accounId = this.$store.state.account.id;
      const form = this.form;

      const params = [];

      if (form.id) {
        params.push(`id;eq;'${form.id}'`);
      } else {
        if (form.status) params.push(`status;eq;'${form.status}'`);

        if (form.recipient) {
          // Contactmos o cliente
          params.push('message_origin;ne;\'CLIENT\'');
          params.push(`client_number;eq;'${encodeURIComponent(form.recipient)}'`);
        } else if (form.sender) {
          // Cliente nos responde
          params.push('message_origin;eq;\'CLIENT\'');
          params.push(`client_number;eq;'${encodeURIComponent(form.sender)}'`);
        }

        if (form.date_begin) {
          params.push(`timestamp;gte;'${new Date(form.date_begin).toISOString()}'`);
        }

        if (form.date_end) {
          params.push(`timestamp;lte;'${new Date(form.date_end).toISOString()}'`);
        }

        if (form.customer_ids && form.customer_ids.length) {
          params.push(`whatsapp_business.account_id;in;'${form.customer_ids.join('\',\'')}'`);
        } else {
          params.push(`whatsapp_business.account_id;eq;'${accounId}'`);
        }
      }

      const orderBy = 'orderBy=timestamp;desc';

      WhatsService.getNewHistory(`query[]=${params.join('&query[]=')}&page=${form.page}&${orderBy}`).then(
        (response) => {
          console.log('response.data', response.data);
          this.fetched = true;
          this.messages = response.data.content;
          this.pages = response.data.totalPages;
          console.log('FETCH WHATSAPP', response);
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.date_begin = this.beginDate;
      this.form.date_end = this.endDate;
    },
  },
};
</script>

<style scoped lang="scss">
/* .btn-search-id {
  height: 35px;
} */
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left: unset;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-control.search-form,
.rebranding .form-control.search-form {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left: unset;
}

.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.darkmode .nav-tabs .nav-link.active {
  background-color: var(--background-3);
}

.darkmode .nav.nav-tabs .nav-item .nav-link:hover,
.darkmode .nav.nav-tabs .nav-item .nav-link:hover i {
  color: #fff;
}
</style>
